import React from "react";
import { logAnalyticEvent } from '../../../../firebase.ts';

export const formatContent = (content) => (
    content.map(par => (
        <p key={par.key} className={`flex flex-1 text-xl xl:text-2xl my-4 ${par.style === undefined ? "" : par.style}`}>{par.text}</p>
    ))
);

export const isLastStep = (stage) => 3 === stage;
export const isFirstStep = (stage) => 0 === stage;

export const onMoveStage = (delta, stage, setStage) => {
    const newStage = stage + delta;
    logAnalyticEvent(`user_viewed_stage_${newStage}`);
    setStage([stage, newStage]);
};