import React from 'react'

const ChatInput = ({className}) => {

    return (
        <div className={`flex flex-col h-36 justify-start items-center mx-2 ${className}`}>
            <div className='flex flex-col h-20 mb-2 w-full border border-primary-border rounded-lg mt-2'>
                <div className='flex flex-row items-center rounded-t-lg bg-primary-lighter px-4 flex-grow-3' >
                    <i className="fas fa-info-circle mr-2" />
                    <p className=''>Followers-Only Chat</p>
                </div>
                <div className='flex flex-row items-center bg-primary-darker rounded-b-lg pl-2 flex-grow-6 focus-within:outline focus-within:outline-buttons-darkest focus-within:bg-black'>
                    <div className='flex items-center justify-center mr-2 rounded-md h-6 w-6 hover:cursor-pointer hover:bg-chatButtons-lighter'>
                        <i className="far fa-star" />
                    </div>
                    <input type='text' placeholder='Send a message' className='flex-1 outline-none border-none bg-transparent' />
                    <div className='flex items-center justify-center mr-2 rounded-md h-6 w-6 hover:cursor-pointer hover:bg-chatButtons-lighter'>
                        <i className="fab fa-ethereum" />
                    </div>
                    <div className='flex items-center justify-center mr-2 rounded-md h-6 w-6 hover:cursor-pointer hover:bg-chatButtons-lighter'>
                        <i className="far fa-grin" />
                    </div>
                </div>
            </div>

            <div className='flex h-10 pt-2 w-full mb-2'>
                <div className='flex items-center justify-center rounded-md p-2 w-auto mb-2 hover:cursor-pointer hover:bg-chatButtons-lighter' >
                    <div className='flex items-center justify-center '>
                        <i className="far fa-clock mr-1" />
                    </div>
                    <p className=''>10</p>
                </div>
                <div className='flex-1' />
                <div className='flex items-center justify-center rounded-md p-2 w-auto mb-2'>
                    <i className="fas fa-cog mr-1" />
                </div>
                <div className='flex items-center justify-center rounded-md p-2  w-auto mb-2 bg-buttons-darkest rounded-b-md, ml-2 hover:bg-buttons-darker hover:cursor-pointer'>
                    Chat
                </div>
            </div>
        </div>
    )
}

export default ChatInput
