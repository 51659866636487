import React, {useEffect} from 'react'
import Layout from '../components/layout/Layout';
import Hero from '../components/Hero';
import ProductDescription from '../components/ProductDescription';
import CardHolder from '../components/CardHolder'
import Demo from '../components/demo/Demo';
import './index.css';
import WaitingListForm from '../components/WaitingListForm';
import { getFirebase, init } from '../../firebase.ts';

const delay = '100';

const Index = () => {
  const firebase = getFirebase();

  useEffect(() => {
    if (!firebase) return;

    init(firebase);
  }, [firebase])

  return (
    <Layout>
      <Hero id='hero' />
      <ProductDescription id='product' dataSal='slide-up' salDelay={delay}/>
      <CardHolder id='features' dataSal='slide-up' salDelay={delay}/>
      <Demo id='demo' dataSal='slide-up' salDelay={delay}/>
      <WaitingListForm id='waitingList' dataSal='slide-up' salDelay={delay}/>
    </Layout>
  );
};

export default Index;
