import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

const getWidth = (size) => {
    switch(size)
    {
        case 'lg': return ['w-72', 'text-6xl'];
        case 'md': return ['w-32', 'text-2xl'];
        default: return ['w-24', 'text-xl'];
    }
}

const VideoThumbnail = ({size}) => {
    const [width, playSize] = getWidth(size);

    return (
        <div className={`grid grid-cols-5 grid-rows-5 ${width} aspect-video m-3 items-center justify-center border border-primary-border`}>
            <StaticImage loading="eager" src='../../../images/thumbnail.png' alt='thumbnail' className='w-full h-full col-start-1 col-end-6 row-start-1 row-end-6 z-0' />
            <div className='flex justify-center items-center col-start-3 col-span-1 row-start-3 row-span-1 z-10'>
                <i className={`fas fa-play text-white ${playSize}`} />
            </div>
        </div>
    )
}

export default VideoThumbnail
