import React from 'react'
import { useStage } from '../../../hooks/stageProvider'
import HighlightsNFT from '../highlightsNFT/HighlightsNFT'
import InChatStageDescriptor from '../stageDescriptor/InChatStageDescriptor'
import ChatBody from './ChatBody'
import ChatHeader from './ChatHeader'
import ChatInput from './ChatInput'

const Chat = ({innerRef, className}) => {
    const stage = useStage();
    const elem = stage[1] !== 0 ? <HighlightsNFT /> : <></>;

    return (
        <div ref={innerRef} tabIndex={-1} className={`flex flex-col max-h-screen h-[91vh] sm:h-[85vh] ${className || ''}`}>
            <ChatHeader className='xl:blur-sm' />
            {elem}
            <ChatBody className='hidden xl:flex' />
            <InChatStageDescriptor className='flex xl:hidden' />
            <ChatInput className='xl:blur-sm' />
        </div>
    )
}

export default Chat
