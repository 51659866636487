import React from 'react'

const ChatHeader = ({className}) => {
    return (
        <div className={`flex justify-between h-11 py-2 border-b-primary-border border-b items-center rounded-t-2xl ${className}`}>
            <div className='flex items-center justify-center h-8 w-8 mx-2 rounded-md hover:bg-chatButtons-default hover:cursor-pointer'>
                <i className="fas fa-arrow-right" />
            </div>

            <h5>STREAM CHAT</h5>

            <div className='flex items-center justify-center h-8 w-8 mx-2 rounded-md hover:bg-chatButtons-default hover:cursor-pointer'>
            <i className="fas fa-user-friends" />
            </div>
        </div>
    )
}

export default ChatHeader
