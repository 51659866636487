import React from 'react'
import { Formatter } from '../../../common/common';
import VideoThumbnail from './VideoThumbnail';

const HighlightsWinner = ({ winner }) => {
    return (
        <div className='flex flex-col w-full h-52 border-b border-b-primary-border'>
            <div className='flex flex-col lg:flex-row w-full h-auto mt-2 items-center justify-center'>
                <div className='flex flex-1 items-center justify-center h-auto w-full text-2xl xl:text-3xl font-bold bg-gradient-to-l bg-clip-text text-transparent from-purple-600 to-indigo-600'>
                    And.. We have a winner!!
                </div>
            </div>
            <div className='flex flex-1 items-center mb-1 w-full'>
                <div className='flex flex-3 h-full items-center justify-center'>
                    <VideoThumbnail size='md' />
                </div>
                <div className='flex flex-col flex-4 pt-2 w-full pr-5'>
                    <div className='flex text-xl items-start mb-1 w-full'>
                        <span className='flex-1 font-bold text-buttons-lighter w-auto'>Winner:</span> {winner.name}
                    </div>
                    <div className='flex text-xl items-start mb-1 w-full'>
                        <span className='flex-1 font-bold text-buttons-lighter w-auto'>Bid:</span> {Formatter.format(winner.bid)} <br />
                    </div>
                    <p className='flex flex-9 flex-col text-lg'>Congratz!!!</p>
                </div>
            </div>
        </div>
    )
}

export default HighlightsWinner
