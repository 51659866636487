import React from 'react'
import VideoThumbnail from './VideoThumbnail'

const HighlightsBidStarting = ({ title }) => {
    return (
        <div className='flex h-36 w-full items-center border-b border-b-primary-border'>
            <div className='flex flex-3 mx-2 items-center justify-center'>
                <VideoThumbnail size='md' />
            </div>
            <div className='flex flex-8 flex-col'>
                <div className='flex flex-2 justify-start items-end text-3xl text-buttons-lighter my-2'>
                    {title}
                </div>
                <p className='flex flex-3 text-xl font-normal items-start mb-5'>
                    Bidding is about to start!<br />
                    Get Ready!!!
                </p>
            </div>
        </div>
    )
}

export default HighlightsBidStarting
