import React, { useRef } from 'react';
import Chat from './chat/Chat'
import StageDescriptor from './stageDescriptor/StageDescriptor'
import StageProvider from '../../hooks/stageProvider';

const Demo = ({ id, dataSal, salDelay}) => {
    const chatRef = useRef(null);
    const descriptorRef = useRef(null);

    return (
        <StageProvider>
            <div id={id} data-sal-delay={salDelay} data-sal={dataSal || ''} className='flex flex-col items-center'>
                <h2 className="text-5xl font-bold ">Here, have a look</h2>
                <div className='flex flex-row container py-10 h-full mx-auto justify-center'>
                    <StageDescriptor tabIndex={-1} innerRef={descriptorRef} className='flex-1 border-y border-l border-primary-border rounded-l-2xl hidden xl:flex' />
                    <Chat tabIndex={-1} innerRef={chatRef} className='border border-primary-border rounded-2xl xl:rounded-l-none w-[24rem] sm:w-[30rem] xl:w-[25rem]' />
                </div>
            </div>
        </StageProvider>
    )
}

export default Demo
