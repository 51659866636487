import React, {useState, useEffect, useRef} from 'react'
import ChatRecord from './ChatRecord'
import content from "../../../content/chat-content.json"
import './chatBody.css'

const initMessages = [{ "color": "gold", "sender": "C-3PO", "content": "I'm in love!!!!!", "time": 30 }]

const getRandomMessages = (maxAmount, messageCounter, messages) => {
    const newMessageCount = Math.floor(Math.random() * maxAmount) + 1;
    const newMessages = [];
    for(let i = 0; i < newMessageCount; i++)
    {
        const msg = messages.shift();
        newMessages.push({...msg, key: i + messageCounter});
        messages.push(msg);
    }

    return newMessages;
}

const ChatBody = ({className}) => {
    const bodyRef = useRef(null);
    const lastMessageRef = useRef(null);
    const [messageCounter, setMessageCounter] = useState(100)
    const [messages, setMessages] = useState(initMessages);
    const [contentState, setContentState] = useState(content);
    useEffect(() => {
        const interval = setInterval(() =>{
            setMessages(prev => {
                const lenDiff = messages.length - 1000;
                const newMessages = [...(prev.slice(lenDiff > 0 ? lenDiff : 0))];
                const randomMessages = getRandomMessages(10, messageCounter, contentState);
                setContentState(contentState);
                setMessageCounter(prevCounter => {
                    const newCounter = (prevCounter + randomMessages.length) % 1000000;
                    return newCounter;
                });
                randomMessages.forEach(msg => {
                    newMessages.push(msg);
                })
        
                return newMessages;
            });
        }, 500);

        if (lastMessageRef.current && bodyRef.current) {
            bodyRef.current.scrollTo({top: lastMessageRef.current.offsetTop, left: 0, behavior: 'smooth'});
        }

        return () => {
            clearInterval(interval);
        }
    }, [messages, messageCounter, contentState]);

    const messagesCpy = messages.slice();
    const lastMessage = messagesCpy.pop();
    const formatRecords = (records) => (
        records.map(message => {
            return (
                <ChatRecord 
                    key={`${message.sender}-${message.content}-${message.key}`} 
                    color={message.color} 
                    sender={message.sender} 
                    content={message.content} />
            )
        })
    );

    return (
        <div ref={bodyRef} className={`${className} flex-1 overflow-y-hidden pr-2 pb-0 flex-col blur-sm`}>
            {formatRecords(messagesCpy)}
            <ChatRecord 
                id='lastMessage'
                key={`${lastMessage.sender}-${lastMessage.content}-${lastMessage.key}`}
                color={lastMessage.color}
                sender={lastMessage.sender}
                content={lastMessage.content}
                innerRef={lastMessageRef}
            />
        </div>
    )
}


export default ChatBody
