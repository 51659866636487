import React from 'react'
import Card from './Card'
import holderContent from "../content/cards-content.json"

const createCard = (card) => (
    <div key={card.title} className="flex-1 px-3">
        <Card className="mb-8 h-full pb-10">
            <div className='flex justify-center items-center w-full mb-4'>
                <p className='flex-1 text-5xl'>{card.icon}</p>
            </div>
            <p className="font-semibold lg:font-bold text-2xl">{card.title}</p>
            <p className="mt-4 text-xl text-center-">
                {card.content}
            </p>
        </Card>
    </div>
)

const CardHolder = ({ id, dataSal, salDelay }) => {
    const cards = holderContent.cards.map(createCard);

    return (
        <section data-sal={dataSal || ''} data-sal-delay={salDelay} id={id} className="xl:container xl:px-auto xl:mx-auto py-2 mb-20">
            <div className="flex flex-col mx-2 xl:mx-auto text-center">
                <h2 className="text-5xl font-bold xl:mt-20">{holderContent.holderTitle}</h2>
                <div className="flex flex-col xl:flex-row sm:mx-3 mt-12 h-auto">
                    {cards}
                </div>
            </div>
        </section>
    )
}

export default CardHolder
