import React from 'react'
import { useStage, useSetStage } from '../../../hooks/stageProvider'
import content from '../../../content/stage-descriptor-content.json'
import { isFirstStep, isLastStep, onMoveStage } from './common'

const formatContent = (content) => (
    content.map(par => (
        <p key={par.key} className={`flex flex-1 text-2xl my-4 ${par.style === undefined ? "" : par.style}`}>{par.text}</p>
    ))
);

const formatButton = (text, icon, condition, onClick, reverseOrder) => (
    <div className='flex w-52 justify-center items-center pt-10'>
        {
            <button 
                className={`flex h-auto justify-center items-center text-xl lg:text-5xl rounded-lg px-6 py-4 sm:px-3 text-grad animate-bounce ${condition ? '' : 'invisible'} ${reverseOrder ? 'flex-row-reverse' : "flex-row" }`}
                onClick={onClick} >
                {text}
                <i className={`${icon} px-3 text-9xl`} />
            </button>
        }
    </div>
);

const StageDescriptor = ({ innerRef, className }) => {
    const stage = useStage()[1];
    const setStage = useSetStage();
    const isFirstStage = isFirstStep(stage);
    const isLastStage = isLastStep(stage);
    const onClick = (delta) => onMoveStage(delta, stage, setStage);

    return (
        <div className={`flex ${className || ''}`}>
            <div className='flex h-full w-auto justify-center items-center'>
                { formatButton("Last Step ", "fas fa-chevron-left", !isFirstStage, () => onClick(-1), true) }
            </div>

            <div className='flex flex-1 flex-col justify-center'>
                <h3 className='pb-8 px-6 font-semibold text-6xl'>{content[stage].title}</h3>
                <div className='px-6'>
                    {formatContent(content[stage].content)}
                </div>
            </div>

            <div className='flex h-full justify-center items-center' >
                { formatButton("Next Step ", "fas fa-chevron-right", !isLastStage, () => onClick(1), false) }
            </div>
        </div>
    )
}

export default StageDescriptor
