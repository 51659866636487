import React from 'react'
import content from '../content/product-description.json'

const formatText = (content) => {
    const lines = content.map((line, index) => (
        <p key={index} className='text-md md:text-lg lg:text-xl xl:text-2xl font-semibold text-center justify-content mb-4'>
            {line}
        </p>
    ));

    return (
        <div className='flex flex-col container sm:px-10 lg:px-52 '>
            {lines}
        </div>
    )
}

const ProductDescription = ({id, dataSal, salDelay}) => {
    return (
        <section data-sal={dataSal || ''} data-sal-delay={salDelay} id={id} className='flex flex-col justify-self-center justify-start items-center w-full mb-10 px-2 md:px-36'>
            <h3 className="text-5xl font-bold mb-10">{content.title}</h3>
            {formatText(content.text)}
        </section>
    )
}

export default ProductDescription
