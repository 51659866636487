import React, { useRef } from 'react'
import { Formatter } from '../../../common/common';
import './highlightsExtended.css'
import VideoThumbnail from './VideoThumbnail';

const HighlightsExtended = ({ bids, title, description, onAddBid, onReducerClick }) => {
    const inputRef = useRef(null);

    const onBid = () => {
        const val = inputRef.current.value;
        if (val !== undefined && val.length > 0) {
            const newBids = bids.slice();
            const newBid = {
                name: 'MvP',
                bid: ~~val, 
                time: Date.now()
            }

            newBids.push(newBid);
            onAddBid(newBids)
        }
    }

    const formatLeader = (bid, index) => {
        return (
            <tr className='max-h-5' key={`${bid.name}-${bid.bid}-${bid.time}`}>
                <td>{index + 1}</td>
                <td>{bid.name}</td>
                <td>{Formatter.format(bid.bid)}</td>
            </tr>
        )
    }

    return (
        <div className='flex flex-col w-full h-[34rem] items-center border-b-primary-border'>
            <div className='flex flex-col h-auto w-11/12'>
                <div className='flex w-full text-buttons-lighter text-3xl font-normal items-center mt-2 mx-1'>
                    {title}
                </div>
                <div className='flex flex-1 w-full mt-2 mx-2 text-xl font-normal'>
                    {description}
                </div>
            </div>

            <div className='flex items-center justify-center w-full h-76'>
                <VideoThumbnail size='lg' />
            </div>

            <div className='flex items-center w-11/12 h-10'>
                <input type='number' className='flex-1 mx-2 bg-primary-lighter border-none rounded-md h-4/6 pl-2' placeholder='Place your bid here' ref={inputRef} />
                <button onClick={onBid} className='mr-2 w-12 h-3/4 rounded-md bg-chatButtons-default border-none hover:cursor-pointer hover:bg-primary-darker'>Bid</button>
            </div>

            <div className='flex flex-1 w-11/12 pr-2 overflow-y-hidden hover:overflow-y-auto hover:pr-0'>
                <table className='w-full h-full m-2 pb-2'>
                    <thead>
                        <tr>
                            <th className='text-left text-base text-buttons-darkest'>#</th>
                            <th className='text-left text-base text-buttons-darkest'>Name</th>
                            <th className='text-left text-base text-buttons-darkest'>Bid</th>
                        </tr>
                    </thead>
                    <tbody className='overflow-y-hidden'>
                        {bids.map(formatLeader)}
                    </tbody>
                </table>
            </div>

            <button onClick={onReducerClick} className='flex h-6 items-center justify-center w-full border-y border-y-primary-border hover:cursor-pointer hover:bg-primary-darker'>
                <i className="fas fa-chevron-up text-lg animate-ping" />
            </button>
        </div>
    )
}

export default HighlightsExtended
