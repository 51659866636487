export const Formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
});

const appendZeroIfNeeded = (num) => num < 10 ? `0${num}` : num.toString();
export const formatDuration = (duration) => {
    let h = appendZeroIfNeeded(Math.floor(duration.asHours()));
    let m = appendZeroIfNeeded(Math.floor(duration.minutes()));
    let s = appendZeroIfNeeded(Math.floor(duration.seconds()));
    return `${h}:${m}:${s}`;
}
