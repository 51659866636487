import React from 'react'

const ChatRecord = ({id, color, sender, content, innerRef}) => {
    const style = {
        color: color
    }

    return (
        <div id={id} ref={innerRef} className='flex p-1 w-full blur-sm'>
            <p className='break-all whitespace-normal pr-1'><span style={style}>{sender}: </span>{content}</p>
        </div>
    )
}

export default ChatRecord
