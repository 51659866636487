import React from 'react';

const Button = ({ children, className = '', size, onClick }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`
        ${className}
        bg-grad
        bg-hover-grad
        rounded
        text-white
    `}
    >
      {children}
    </button>
  );
};

export default Button;
