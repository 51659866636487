import React from 'react';
import { useStage, useSetStage } from '../../../hooks/stageProvider'
import content from '../../../content/stage-descriptor-content.json'
import { isLastStep, isFirstStep, onMoveStage } from './common';

const formatContent = (content) => (
  content.map(par => (
      <p key={par.key} className='flex flex-1 text-md my-2'>{par.text}</p>
  ))
);

const formatButton = (text, onClick, condition, className) => (
  <button onClick={onClick} className={`text-2xl font-semibold px-4 py-2 ${className} ${condition ? '' : 'invisible'}`}>
    {text}
  </button>
)

const InChatStageDescriptor = ({ className }) => {
  const stage = useStage()[1];
  const setStage = useSetStage();
  const isFirstStage = isFirstStep(stage);
  const isLastStage = isLastStep(stage);
  const onClick = (delta) => onMoveStage(delta, stage, setStage);

  return (
    <div className={`${className} flex-col flex-1 overflow-y-hidden`}>
      <div className='flex flex-col flex-1 justify-center overflow-y-hidden'>
        <h3 className='pb-2 px-2 font-semibold text-3xl'> {content[stage].title} </h3>
        <div className='flex flex-col px-2 justify-start overflow-y-auto'>
          {formatContent(content[stage].content)}
        </div>
      </div>

      <div className='flex h-auto w-full overflow-y-hidden'>
        {formatButton("< Last Step", () => onClick(-1), !isFirstStage, 'text-purple-600')}
        <div className='flex-1'></div>
        {formatButton("Next Step >", () => onClick(1), !isLastStage, 'text-indigo-600')}
      </div>
    </div>
  );
};

export default InChatStageDescriptor;
