import React, { useRef, useState } from 'react'
import Button from './Button'
import content from "../content/waiting-list-content.json"
import { validate } from 'email-validator'
import { addEmail } from '../../firebase.ts'

const onAddEmail = async (email, isStreamer, isViewer) => {
    if (validate(email)) {
        await addEmail({ email, isStreamer, isViewer });
        return true
    }

    return false;
}

const formatCheckbox = (text, name, ref) => (
    <div className='flex text-xl sm:text-2xl items-center'>
        <input className='mx-4 h-6 w-6 rounded-xl' ref={ref} type='checkbox' name={name} />
        <label for={name}>{text}</label>
    </div>
);

const WaitingListForm = ({ id, dataSal, salDelay }) => {
    const [success, setSuccess] = useState(false);
    const inputRef = useRef(null)
    const isStreamerRef = useRef(null);
    const isViewerRef = useRef(null);
    const onClick = async () => {
        if (inputRef.current) {
            inputRef.current.disabled = true
            console.log(inputRef.current.value)
            if (await onAddEmail(inputRef.current.value, isStreamerRef.current.checked, isViewerRef.current.checked)) {
                inputRef.current.value = '';
                setSuccess(true);
            }
            else {
                inputRef.current.style.outlineColor = '#CC0000'
                setSuccess(false);
            }

            inputRef.current.disabled = false
        }
    }

    const onChange = () => {
        if (inputRef.current) {
            inputRef.current.style.outlineColor = 'transparent';
        }
    }

    return (
        <section data-sal={dataSal || ''} id={id} data-sal-delay={salDelay} className="flex flex-col items-center rounded-lg text-center w-full mt-20">
            <div className='flex flex-col items-start w-full md:w-auto'>
                <div className='flex flex-col w-full items-center gap-y-4'>
                    <h3 className="text-3xl sm:text-5xl font-semibold">{content.title}</h3>
                    <h5 className="text-xl sm:text-2xl font-semibold text-gray-500">{content['sub-title']}</h5>
                </div>

                <div className='flex flex-col h-auto mt-16 w-full md:w-auto'>
                    <div className='flex flex-col items-start gap-2 my-4 mx-2 w-full'>
                        {formatCheckbox("I'm a streamer", 'isStreamer', isStreamerRef)}
                        {formatCheckbox("I'm a viewer", 'isViewer', isViewerRef)}
                    </div>

                    <div className='flex justify-center w-full px-0'>
                        <div className="flex flex-col sm:flex-row sm:h-16 justify-center items-center w-11/12 sm:w-85-wscreen max-w-5xl ">
                            <input onChange={() => onChange()} ref={inputRef} placeholder='Enter your email address' type='email' className='sm:flex-1 w-full justify-center h-12 sm:h-full border-none my-0 rounded-xl sm:rounded-r-none sm:rounded-l-2xl pl-3 outline-none outline-offset-[-1px] text-gray-800 font-semibold text-2xl' />
                            <Button onClick={() => onClick()} className='flex justify-center items-center max-w-lg px-6 h-14 mt-3 sm:my-0 sm:h-full rounded-xl sm:rounded-l-none sm:rounded-r-2xl text-xl w-auto'>{content.buttonText}</Button>
                        </div>
                    </div>
                </div>

                <div className={`flex justify-center items-center m-6 py-2 h-auto text-6xl text-grad ${success ? '' : ' hidden'}`}>
                    Thank you!
                </div>
            </div>
        </section>
    )
}

export default WaitingListForm
