import React from 'react'
import { Formatter } from '../../../common/common';
import VideoThumbnail from './VideoThumbnail';

const leadersColor = ['gold', 'silver', '#CD7F32'].map((color) => ({ color: color }));


const FormatLeader = (bid, position) => {
    return (
        <div key={`${bid.name}-${bid.bid}`} className='flex w-full my-1 pr-5'>
            <p className='text-xl font-semibold w-auto' style={leadersColor[position]}>{position + 1}. {bid.name}</p>
            <div className='flex-1'></div>
            <p className='text-xl w-auto'>{Formatter.format(bid.bid)}</p>
        </div>
    );
}

const getUniqueLeaders = (leaders, maxAmount) => {
    let diffenetLeaders = new Set();
    const length = leaders.length;
    let i = 0;
    let results = [];
    while (i < length && diffenetLeaders.size < maxAmount) {
        const leader = leaders[i++];
        if (!diffenetLeaders.has(leader.name)) {
            diffenetLeaders.add(leader.name);
            results.push(leader);
        }
    }

    return results;
}

const HighlightsBanner = ({ bids, title, onClick }) => {
    const leaders = getUniqueLeaders(bids, 3);

    return (
        <div className='flex flex-col h-48 w-full border-b border-b-primary-border'>
            <div className='flex items-center h-10 pr-5'>
                <div className='flex flex-1 justify-start items-end h-full pt-6 pl-5 text-3xl text-buttons-lighter'>
                    {title}
                </div>
            </div>

            <div className='flex flex-1 w-full items-center'>
                <div className='flex flex-3 items-end justify-center my-2 '>
                    <VideoThumbnail size='md' />
                </div>

                <div className='flex flex-4 flex-col w-full justify-start items-start'>
                    {leaders.map((bid, index) => FormatLeader(bid, index))}
                </div>
            </div>
            <button onClick={onClick} className='flex items-center justify-center border-t border-t-primary-border h-6 hover:cursor-pointer hover:bg-primary-darker'>
                <i className="fas fa-chevron-down text-lg animate-ping" />
            </button>
        </div>
    )
}

export default HighlightsBanner
