import React from 'react'
import content from '../content/hero-content.json'
import Button from './Button'
import { Link } from 'gatsby'

const Hero = ({ id }) => {
    return (
        <section id={id} className="flex h-auto -mt-52 md:-mt-24 pt-0 justify-center mb-20">
            <div className='relative flex h-auto w-full'>
                <div className="flex flex-1 flex-col container mx-auto pt-64 md:pt-40 px-8 lg:flex bg-hero bg-cover bg-center pb-40">
                    <div className="text-center lg:text-left lg:w-1/2 z-20">
                        <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
                            {content.title}
                        </h1>
                        <p className="text-xl lg:text-2xl mt-6 font-light">
                            {content.description}
                        </p>
                        <p className="mt-8 md:mt-12">
                            <Link to='#waitingList'>
                                <Button className='text-2xl font-semibold rounded-md py-4 px-12'>
                                    {content.button.text}
                                </Button>
                            </Link>
                        </p>
                    </div>
                </div>
                <div className='absolute h-full inset-0 bg-gradient-to-r from-purple-800 to-indigo-700 mix-blend-multiply' />
            </div>
        </section>
    )
}

export default Hero
